<!--设备模块--设备联动控制页--设备编辑页-->
<template>
  <div
    id="terminalEditor"
    :element-loading-text="$t('terminaleditor.a1')"
    ref="terminaleditor"
  >
    <div class="header">
      <my-headertitle :quit="true"
        >{{name}}
        <template v-slot:name>
          <a-icon
            type="pushpin"
            :style="{ 'margin-right': '5px', color: '#7682CE' }"
          />
          <!-- <a-icon
            type="arrow-right" 
            :style="{ fontSize: '20px', color: '#7682CE' }"
            @click="goBack"
          /> -->
          <span>{{nodeName}}</span>
        </template>
      </my-headertitle>
    </div>
    <div class="inquire">
      <a-form-model :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="form_row">
          <!-- <div class="form_col"> -->
            <a-form-model-item :label="$t('general.a16')">
              <a-select v-model="form.key" style="width: 100px">
                <a-select-option v-for="item in form.options" :value="item.en" :key="item.no">
                  {{item.cn}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          <!-- </div>
          <div class="form_col"> -->
            <a-form-model-item :label="$t('general.a17')">
              <a-input v-model="form.value"  />
            </a-form-model-item>
          <!-- </div>        
          <div class="form_col"> -->
            <a-form-model-item>
              <a-button type="primary"  @click="inquire">{{$t('terminaleditor.a4')}}</a-button>
            </a-form-model-item>
          <!-- </div> -->
        </div>
      </a-form-model>
    </div>
    <div class="main">
      <div class="main-all-vessel" v-loading="allLoading">
        <my-tabletitle>{{$t('terminaleditor.a5')}}</my-tabletitle>
        <!-- <a-button @click="clearSelected">清空</a-button> -->
        <a-table
          :row-selection="allrowSelection"
          :rowKey="(record) => record.key"
          :columns="columns"
          :dataSource="alterallList"
          :scroll="{ x: 1300,y:150 }"
          :pagination="pagination"
          size="small"
        >
        </a-table>
      </div>
      <div class="main-button-vessel">
        <a-button type="primary" style="width: 120px" @click="add"
          >↓ {{$t('terminaleditor.a6')}}</a-button
        >
        <a-button type="primary" style="width: 120px" @click="Delete"
          >↑ {{$t('terminaleditor.a7')}}</a-button
        >
      </div>
      <div class="main-register-vessel" v-loading="registerLoading">
        <my-tabletitle>{{$t('terminaleditor.a8')}}</my-tabletitle>
        <a-table
          :row-selection="rowSelection"
          :rowKey="(record) => record.key"
          :columns="columns"
          :dataSource="registerList"
          :scroll="{ x: 1300,y:150 }"
          :pagination="pagination"
          size="small"
        >
        <!-- registerList  alterallList-->
        </a-table>
      </div>
    </div>
    <div class="footer">
      <a-button
        type="primary"
        style="width: 120px"
        @click="update"
        :loading="buttonloading"
        >{{$t('terminaleditor.a9')}}</a-button
      >
    </div>    
  </div>
</template>

<script>
import tabletitle from "../../components/Title/tabletitle";
import headerTitle from "../../components/Title/headerTitle";
import { getInterlockOutputInfo,getSeasonDeviceInfo,getIntermittentInfo,getLightTimetableData,
         getBacsScheduleAllData,getComfortInfo,UpdateregisterList,getTrendDeviceInfo,getTimeTrendDeviceInfo } from "../../api/device";

export default {   
  data() {
    return {
      name: this.$t('terminaleditor.a2'),
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },      
      form: {
        options:[
          {en:"",no:0,cn:""}, 
          {en:"gr1",no:1,cn:"Gr1"}, 
          {en:"gr2",no:2,cn:"Gr2"},
          {en:"gr3",no:3,cn:"Gr3"}, 
          {en:"dvName",no:4,cn:this.$t('terminaleditor.a17')},
          {en:"signalName",no:5,cn:this.$t('terminaleditor.a18')},
        ],
        key:"",
        value: "",
      },
      type: this.$route.query.type,
      schType: this.$route.query.schType,
      nodeName: this.$route.query.nodeName,
      visible: false,
      confirmLoading: false,
      buttonloading: false,
      registerLoading: false,
      allLoading:false,
      registerList: [],
      allList: [],
      alterallList: [],
      pointList:[],
      timeTableList: undefined,
      calendarList: undefined,
      radioValue: 0,
      addList: [],
      deleteList:[],
      allSelectedRowkeys:[],
      selectedRowKeys:[],
      timeTableVlaue: undefined,
      calendarValue: undefined,
      pagination: {
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total + this.$t("terminaleditor.a16")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
        
      },
      columns: [
        // {
        //   title: "NO",
        //   customRender: (text, record, index) =>
        //     `${
        //       (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
        //       index +
        //       1
        //     }`,
        //   ellipsis: true,
        //   align: "center",
        //   width: 70,
        //   fixed: "left",
        // },
        {
          title: "NO",
          dataIndex: "key",
          ellipsis: true,
          width: 70,
          fixed: "left",
        },
        {
          title: "Gr1",
          dataIndex: "gr1",
          width: 120,
          ellipsis: true,
        },
        {
          title: "Gr2",
          dataIndex: "gr2",
          width: 120,
          ellipsis: true,
        },
        {
          title: "Gr3",
          dataIndex: "gr3",
          width: 120,
          ellipsis: true,
        },
        {
          title: this.$t('terminaleditor.a17'),
          dataIndex: "dvName",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t('terminaleditor.a18'),
          dataIndex: "signalName",
          width: 180,
          ellipsis: true,
        },
        {
          title: this.$t('terminaleditor.a19'),
          dataIndex: "signalType",
          width: 100,
          ellipsis: true,
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          width: 100,
          ellipsis: true,
        },
      ],
    };
  },
  activated(){
    console.log("query>>>",this.$route.query);
    this.addList.length=0;
    this.deleteList.length=0;
    this.selectedRowKeys.length=0;
    this.allSelectedRowkeys.length=0;
    this.getName();
    this.getData();    
    this.getAlldata();    
  },
  mounted() {   
    this.addList.length=0;
    this.deleteList.length=0;
    this.selectedRowKeys.length=0;
    this.allSelectedRowkeys.length=0;
    this.getData();    
    this.getAlldata();
    this.getName();
  },
  watch: {
  // 监听全部认证终端数据，对数据进行加工
    allList: {
      handler(newName, oldName) {
        this.refreshAllList();
      },
      deep: true,
    },
  },
  computed: {    
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys=selectedRowKeys;
          this.deleteList = selectedRows;
        },
      };
    },
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.allSelectedRowkeys=selectedRowKeys;
          console.log("allrowSelection allSelectedRowkeys:",selectedRowKeys);
          this.addList = selectedRows;
          console.log("allrowSelection allSelectedRows:",selectedRows);
        },
      };
    },    
  },
  methods: {  
    refreshAllList() {
      let registerList = this.registerList;
      let allListJSON = JSON.stringify(this.allList);
      let allListTemp = JSON.parse(allListJSON);
      // console.log("refreshAllList allListTemp:",allListTemp);
      if(registerList!=null){
        for (let i = 0; i < registerList.length; i++) {
          allListTemp = allListTemp.filter((item) => {
            return item.signalNo !== registerList[i].signalNo;
          });
        }
      }
      this.alterallList = allListTemp;
      // console.log("refreshAllList alterallList:",this.alterallList);
      for(let i=0;i<this.alterallList.length;i++){
        this.alterallList[i].key=i+1;
      }
    },
    clearSelected(){
      this.allSelectedRowkeys=[];
      console.log("clearSelected allSelectedRowkeys:",this.allSelectedRowkeys);
    },
    getName(){
      this.schType=this.$route.query.schType;
      // console.log("getName schType:",this.schType); 
      this.type=this.$route.query.type;
      this.nodeName=this.$route.query.nodeName;
          switch(this.type){
            case 1://　输入点（设备联动控制）
              this.name=this.$t('terminaleditor.a20');
              break;
            case 2://　输出点（设备联动控制）
              this.name=this.$t('terminaleditor.a21');
              break;
            case 3://联锁点编辑（设备联动控制）
              this.name=this.$t('terminaleditor.a22');
              break;
            case 13://间歇点
              this.name=this.$t('terminaleditor.a23');
              break;
            case 14://测量点编辑
              this.name=this.$t('terminaleditor.a24');
              break;
            case 10://模拟设置设备编辑
              this.name=this.$t('terminaleditor.a26');
              break;
            case 11://  上下限预警设置
              this.name=this.$t('terminaleditor.a27');
              break;
            case 12://运行模式设置设备
              this.name=this.$t('terminaleditor.a28');
              break;
            case 16://温度测量点编辑
              this.name=this.$t('terminaleditor.a29');
              break;
            case 17: // 设备时间表控制
              this.name=this.$t('terminaleditor.a2');
              break;
            case 15://空调机点
              this.name=this.$t('terminaleditor.a30');
              break;
            case 19://趋势组
                this.name=this.$t('terminaleditor.a31');
              break;
            case 20://时间设置趋势组
                this.name=this.$t('terminaleditor.a32');
              break;
        };
    },
    // 页面后退
    goBack() {
      this.$router.go(-1);
    },
    // 获取当前已注册的设备信号列表
    getData() {
      this.registerLoading=true;
      this.schType=this.$route.query.schType;
      let par = {
        interlockOutputId: this.$route.query.nodeId,
      };
      
      console.log("getData schType:",this.schType);
      let schType=parseInt(this.schType);
      switch(schType){
        case 6://联锁输出控㓡
          console.log("getData par:",par);
          getInterlockOutputInfo(par)
          .then((res) => {
            let { data } = res;
            let temp = "";
            console.log("getInterlockOutputInfo:",data);
            let type=parseInt(this.type);
            switch(type){
              case 1://　输入点（设备联动控制）
                this.registerList = data.inputList;
                temp ="@@analog";
                break;
              case 2://　输出点（设备联动控制）
                this.registerList = data.outputList;
                temp ="@@binary";
                break;
              case 3://联锁点编辑（设备联动控制）
                this.registerList = data.interlockList;
                temp ="@@binary";
                break;
            }          
            if(this.registerList !=null){
              this.pointList.length =0;
              for (let i = 0; i < this.registerList.length; i++) {
                this.pointList.push(this.registerList[i].signalNo+temp);
                this.registerList[i].key = i + 1;
              } 
            }                    
          })
          .catch((err) => {
            console.log(err);
          });
          break;
        case 7://间歇运行
          par = {
            intermittentId: this.$route.query.nodeId,
          };
          console.log("getData par:",par);
          getIntermittentInfo(par)
          .then((res) => {
            let { data } = res;
            console.log("getIntermittentInfo data:",data);
            let temp = "";
            let type=parseInt(this.type);
            switch(type){
              case 14://测量点编辑
                this.registerList = data.inputList;
                temp ="@@analog";
                break;
              case 13://间歇点
                this.registerList = data.outputList;
                temp ="@@binary";
                break;
            }          
            if(this.registerList !=null){
              this.pointList.length =0;
              for (let i = 0; i < this.registerList.length; i++) {
                this.pointList.push(this.registerList[i].signalNo+temp);
                this.registerList[i].key = i + 1;
              } 
            }                    
          })
          .catch((err) => {
            console.log(err);
          });
          break;
        case 8://季节控制
          par = {
            seasonCtrlId: this.$route.query.nodeId,
          };
          console.log("getData par:",par);
          getSeasonDeviceInfo(par)
          .then((res) => {
            let { data } = res;
            console.log("getSeasonDeviceInfo data:",data);
            let temp = "";
            let type=parseInt(this.type);
            switch(type){
              case 10://模拟设置设备编辑
                this.registerList = data.analogList;
                temp ="@@analog";
                break;
              case 11://  上下限预警设置
                this.registerList = data.boundList;
                temp ="@@analog";
                break;
              case 12://运行模式设置设备
                this.registerList = data.runModeList;
                temp ="@@binary";
                break;
            }          
            if(this.registerList !=null){
              this.pointList.length =0;
              for (let i = 0; i < this.registerList.length; i++) {
                this.pointList.push(this.registerList[i].signalNo+temp);
                this.registerList[i].key = i + 1;
              } 
            }                    
          })
          .catch((err) => {
            console.log(err);
          });
          break;
        case 9:
          par = {
            bacsScheduleId: this.$route.query.nodeId,
          };
          console.log("getData par:",par);
          getLightTimetableData(par)
            .then((res) => {
              let { data } = res;
              this.data = data;
              console.log("getLightTimetableData data:",data);
              this.registerList = data;
              if(this.registerList !=null){
                this.pointList.length =0;
                for (let i = 0; i < this.registerList.length; i++) {
                  this.pointList.push(this.registerList[i].signalNo+"@@binary");
                  this.registerList[i].key = i + 1;
                } 
              }                    
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case 10://空调舒适控制
          par = {
            comfortId: this.$route.query.nodeId,
          };
          console.log("getData par:",par);
          getComfortInfo(par)
          .then((res) => {
            let { data } = res;
            console.log("getComfortInfo data:",data);
            let temp = "";
            let type=parseInt(this.type);
            switch(type){
              case 16://温度测量点编辑
                this.registerList = data.measureList;
                temp ="@@analog";
                break;
              case 15://空调机点
                this.registerList = data.monitorList;
                temp ="@@binary";
                break;
            }          
            if(this.registerList !=null){
              this.pointList.length =0;
              for (let i = 0; i < this.registerList.length; i++) {
                this.pointList.push(this.registerList[i].signalNo+temp);
                this.registerList[i].key = i + 1;
              } 
            }                    
          })
          .catch((err) => {
            console.log(err);
          });
          break;
        case 11:
          par = {
            siteId:this.$route.query.id,
            trendGroupId: this.$route.query.nodeId,
            trendGroupName: this.nodeName
          };
          console.log("getData par:",par);
          getTrendDeviceInfo(par)
          .then((res) => {
            let { data } = res;
            console.log("getTrendDeviceInfo data:",data);
            this.registerList.length=0;
          if(data.binaryInfoList!=null && data.binaryInfoList.length>0){
              for(let i=0;i<data.binaryInfoList.length;i++)
                this.registerList.push(data.binaryInfoList[i]);   
          }         
          if(data.analogInfoList!=null && data.analogInfoList.length>0)
            for(let i=0;i<data.analogInfoList.length;i++)
                this.registerList.push(data.analogInfoList[i]);
          if(data.multiStateInfoList!=null && data.multiStateInfoList.length>0)
            for(let i=0;i<data.multiStateInfoList.length;i++)
                this.registerList.push(data.multiStateInfoList[i]);
          if(data.accumulatorInfoList!=null && data.accumulatorInfoList.length>0)
            for(let i=0;i<data.accumulatorInfoList.length;i++)
                this.registerList.push(data.accumulatorInfoList[i]);
          if(data.calculateInfoList!=null && data.calculateInfoList.length>0)
            for(let i=0;i<data.calculateInfoList.length;i++)
                this.registerList.push(data.calculateInfoList[i]);     
        
            if(this.registerList !=null){
              this.pointList.length =0;
              for (let i = 0; i < this.registerList.length; i++) {
                this.registerList[i].key = i + 1;
                // this.pointList.push(this.registerList[i].signalNo+temp);                
              } 
            }                    
          })
          .catch((err) => {
            console.log(err);
          });
          break;
        case 12:
          par = {
            siteId:this.$route.query.id,
            timeTrendGroupId: this.$route.query.nodeId,
            timeTrendGroupName: this.nodeName
          };
          console.log("getData par:",par);
          getTimeTrendDeviceInfo(par)
          .then((res) => {
            let { data } = res;
            console.log("getTimeTrendDeviceInfo data:",data);
            this.registerList.length=0;
          if(data.binaryInfoList!=null && data.binaryInfoList.length>0){
              for(let i=0;i<data.binaryInfoList.length;i++)
                this.registerList.push(data.binaryInfoList[i]);   
          }         
          if(data.analogInfoList!=null && data.analogInfoList.length>0)
            for(let i=0;i<data.analogInfoList.length;i++)
                this.registerList.push(data.analogInfoList[i]);
          if(data.multiStateInfoList!=null && data.multiStateInfoList.length>0)
            for(let i=0;i<data.multiStateInfoList.length;i++)
                this.registerList.push(data.multiStateInfoList[i]);
          if(data.accumulatorInfoList!=null && data.accumulatorInfoList.length>0)
            for(let i=0;i<data.accumulatorInfoList.length;i++)
                this.registerList.push(data.accumulatorInfoList[i]);
          if(data.calculateInfoList!=null && data.calculateInfoList.length>0)
            for(let i=0;i<data.calculateInfoList.length;i++)
                this.registerList.push(data.calculateInfoList[i]);     
        
            if(this.registerList !=null){
              this.pointList.length =0;
              for (let i = 0; i < this.registerList.length; i++) {
                this.registerList[i].key = i + 1;
                // this.pointList.push(this.registerList[i].signalNo+temp);                
              } 
            }                    
          })
          .catch((err) => {
            console.log(err);
          });
          break;
      } 
      this.registerLoading=false;     
    },
    // 获取当前全部的认证终端数据
    getAlldata() {
      this.allLoading=true;  
      let par = {
          siteId: this.$route.query.id,
          bacsScheduleId: this.$route.query.nodeId,
          type:this.type,
          searchKeyword: "",
          pointList: this.pointList.join(","),         
        };     
      console.log("getBacsScheduleAllData par:",par);
      getBacsScheduleAllData(par)
        .then((res) => {
          let { data } = res;                    
          this.allList = data;           
          this.allLoading=false; 
          console.log("getBacsScheduleAllData allList:",this.allList);         
        })
        .catch((err) => {
          console.log(err);
          this.allLoading=false; 
        });       
    },    
    //查询
    inquire(){
      if(this.allList==null||this.allList.length==0)
        this.getAlldata();
      else
        this.inquireFromAllData();
    },
    //在当前所有数据中查询
    inquireFromAllData(){
      if(this.form.key==""){
        this.refreshAllList(); 
        return ;
      }
      this.alterallList.length=0;
      // console.log("inquireFromAllData allList:",this.allList);
      for(let i=0;i<this.allList.length;i++){
        switch (this.form.key) {
          case "gr1":
            if(this.allList[i].gr1!=null&&this.allList[i].gr1.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);
            break;
          case "gr2":
            if(this.allList[i].gr2!=null&&this.allList[i].gr2.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);
            break;
          case "gr3":
            if(this.allList[i].gr3!=null&&this.allList[i].gr3.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);
            break;
          case "dvName":   
            if(this.allList[i].dvName!=null&&this.allList[i].dvName.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);         
            break;
          case "signalName":   
            if(this.allList[i].signalName!=null&&this.allList[i].signalName.indexOf(this.form.value)!=-1)
              this.alterallList.push(this.allList[i]);         
            break;
        }
        for (let i = 0; i < this.registerList.length; i++) {
          this.alterallList = this.alterallList.filter((item) => {
            return item.signalNo !== this.registerList[i].signalNo;
          });
        }
        for(let i=0;i<this.alterallList.length;i++){
          this.alterallList[i].key=i+1;
        }
      }
    },
    // 添加终端
    add() {
      if(this.schType==10||(this.schType==6&&this.type==3)||(this.schType==7&&this.type==14)){//最优化控制只能单选
          if(this.addList.length>1 || this.registerList.length>0){
            this.$message.error(this.$t('terminaleditor.a37')); 
            return;
          }
      }
      if (this.addList.length > 0) {
        for (let i = 0; i < this.addList.length; i++) {
          this.alterallList = this.alterallList.filter((item) => {
            return item.signalNo !== this.addList[i].signalNo;
          });
        }
        this.registerList = this.registerList.concat(this.addList);  
        
        this.addList = [];
        // this.allSelectedRowkeys=[]; //会失败无法清除勾选
        this.allSelectedRowkeys.length=0;
        
        for (let i = 0; i < this.alterallList.length; i++) {
          this.alterallList[i].key = i + 1;
        }
        for (let i = 0; i < this.registerList.length; i++) {
          this.registerList[i].key = i + 1;
        }
        
        
      } else {
        this.$error({
          title: this.$t('terminaleditor.a33'),
          content: this.$t('terminaleditor.a34'),
          centered: true,
        });
      }
    },
    // 删除终端
    Delete() {
      if (this.deleteList.length > 0) {
        for (let i = 0; i < this.deleteList.length; i++) {
          this.registerList = this.registerList.filter(
            (item) => item.signalNo !== this.deleteList[i].signalNo
          );
        }
        this.alterallList = this.alterallList.concat(this.deleteList);
        this.deleteList = [];
        // this.selectedRowKeys=[]; //会失败无法清除勾选
        this.selectedRowKeys.length=0;

        for(let i=0;i<this.alterallList.length;i++){
          this.alterallList[i].key=i+1;
        }
        for (let i = 0; i < this.registerList.length; i++) {
          this.registerList[i].key = i + 1;
        }
        
      } else {
        this.$error({
          title: this.$t('terminaleditor.a33'),
          content: this.$t('terminaleditor.a34'),
          centered: true,
        });
      }
    },   
    update() {
      // let state = true;
      // if (this.registerList == null || this.registerList.length == 0) {
      //   state = false;
      // }      
      // if (state) {
        this.buttonloading = true;
        let par = {
          siteId:this.$route.query.id,
          bacsScheduleId: this.$route.query.nodeId,
          bacsScheduleName: this.nodeName,
          schType:this.schType,
          type:this.type,
          bacsSchDvInfos:this.registerList
        };
        console.log("UpdateregisterList par:",par);
        UpdateregisterList(par)
          .then((res) => {
            this.buttonloading = false;
            console.log(res);
            if (res.errorCode == "00") {
              this.$message.success(res.msg);
            } else if (res.errorCode == "02") {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.buttonloading = false;
            console.log(err);
          });
      // } else {
      //   this.$error({
      //     title: this.$t('terminaleditor.a35'),
      //     content: this.$t('terminaleditor.a36'),
      //     centered: true,
      //   });
      // }
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#terminalEditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 10px #bdbcbc;
  border-radius: 3px;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 10px 20px 0px 20px;
}
.inquire {
  height: 50px;
  padding: 0px 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  white-space: nowrap;
}
.main {
  width: 100%;
  height: calc(100% - 113px);
  padding: 10px 20px;
  overflow: auto;
}
.main-all-vessel {
  overflow: auto;
}
.main-button-vessel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  overflow: auto;
}
.main-button-vessel button {
  margin: 0px 10px;
}
.main-register-vessel {
  overflow: hidden;
}
.form_row {
  display: flex;
  align-items: flex-end;
}
.form_col {
  margin-right: 15px;
  min-width: 1px;
  white-space: nowrap;
}
.name {
  font-size: 15px;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
}
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.margin {
  margin-right: 10px;
}
p {
  margin-bottom: 10px;
}
</style>